<template>
  <div class="body-wrap">
    <div id="mini_wrap">
      <div class="minigame_menu">
        <img src="@/assets/img/mini_title.png">
        <div class="mini_list">
          <a class="">
            <div class="js-powerball-menu-container">
              <span class="css-powerball-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable active">
            <div class="js-powerladder-menu-container">
              <span class="css-powerladder-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable">
            <div class="js-speedkeno-menu-container">
              <span class="css-speedkeno-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable">
            <div class="js-kenoladder-menu-container">
              <span class="css-kenoladder-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
        </div>
      </div>
      <div class="mini_midwrapbox">
        <div class="games-wrap" id="mini_boxwrap">
          <div id="mini_box_right">
            <div class="mini_gamemovie">
              <div class="power_ladder">
                <iframe src="https://minigames.imgforboard.com/mini-games/iframe/7/kr" scrolling="no"></iframe>
              </div>
            </div>
            <div class="mini_timecount">
              <span id="last-play-date">03월 23일 </span>
              <font>[<span id="play_num_view">249</span> 회차]</font>
              <span id="last_play_time">20:40:00</span>
              <b class="count" id="remaind_time">추첨중</b>
              <em>새로고침</em>
            </div>
            <ul class="mini_bettingbtn">
              <li class="w3">
                <dl class="ladder_1st">
                  <dd class="game-odd-active pl-odd">
                    <label><img src="@/assets/img/mini_btn_odd.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                  <dd class="game-odd-active pl-even">
                    <label><img src="@/assets/img/mini_btn_even.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                </dl>
                <em>게임 1</em>
                <span>홀/짝</span>
              </li>
              <li>
                <dl>
                  <dd class="game-odd-active pl-left active">
                    <label><img src="@/assets/img/mini_btn_left.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                  <dd class="game-odd-active pl-right">
                    <label><img src="@/assets/img/mini_btn_right.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                </dl>
                <em>게임 2</em>
                <span>줄갯수</span>
              </li>
              <li>
                <dl>
                  <dd class="game-odd-active pl-line-3">
                    <label><img src="@/assets/img/mini_btn_three.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                  <dd class="game-odd-active pl-line-4">
                    <label><img src="@/assets/img/mini_btn_four.png"></label>
                    <dfn>1.95</dfn>
                  </dd>
                </dl>
                <em>게임 3</em>
                <span>출발점</span>
              </li>
              <li>
                <dl class="type4">
                  <dd class="game-odd-active pl-line-even-left-3">
                    <label>
                      <div class="bet_circle">
                        <h1 class="red">짝</h1>
                        <h2 class="left">3</h2>
                      </div>
                    </label>
                    <dfn>3.8</dfn>
                  </dd>
                  <dd class="game-odd-active pl-line-odd-right-3">
                    <label>
                      <div class="bet_circle">
                        <h1 class="blue">홀</h1>
                        <h2 class="right">3</h2>
                      </div>
                    </label>
                    <dfn>3.8</dfn>
                  </dd>
                  <dd class="game-odd-active pl-line-odd-left-4">
                    <label>
                    <div class="bet_circle">
                      <h1 class="blue">홀</h1>
                      <h2 class="left">4</h2>
                    </div>
                    </label>
                    <dfn>3.8</dfn>
                  </dd>
                  <dd class="game-odd-active pl-line-even-right-4">
                    <label>
                      <div class="bet_circle">
                        <h1 class="red">짝</h1>
                        <h2 class="right">4</h2>
                      </div>
                    </label>
                    <dfn>3.8</dfn>
                  </dd>
                </dl>
                <em>게임 4</em>
                <span>좌우, 출발, 3/4줄</span>
              </li>
            </ul>
            <dl class="mini_bettingslip" id="games-betslip">
              <dt class="col full cart_info">
                <div>
                  <em>게임분류</em>
                  <span class="f_yellow">파워사다리</span>
                </div>
                <div id="selBet">
                  <em>게임선택</em>
                  <span class="tx"></span>
                </div>
                <div id="betRate">
                  <em>배당</em>
                  <span id="bet-odd"></span>
                </div>
              </dt>
              <dd>
                <h2>배팅 금액을 선택하세요</h2>
                <ul>
                  <li>
                    <div class="bet-money">
                    <input name="bet-amount-view" class="bet-amount" value="0" readonly="" type="text">
                    <span>배팅 금액</span>
                    </div>
                    <div>
                    <input class="bet-dividend-view mini_betting_input_hit" value="0" readonly="" type="text">
                    <span>적중 예상금액</span>
                    </div>
                  </li>
                  <li>
                    <code data-value="5,000" class="select-amount">5,000</code>
                    <code data-value="10,000" class="select-amount">10,000</code>
                    <code data-value="50,000" class="select-amount">50,000</code>
                    <code data-value="100,000" class="select-amount">100,000</code>
                    <code data-value="500,000" class="select-amount">500,000</code>
                    <code data-value="1,000,000" class="select-amount">1,000,000</code>
                  </li>
                  <li>
                    <code class="mini_betcalc_btn2 select-amount" data-value="0">잔돈</code>
                    <code class="mini_betcalc_btn3 reset-selected-amount">초기화</code>
                    <code class="mini_betcalc_btn2 select-amount max-amount">
                    최대 </code>
                    <div>
                      <input name="betExp4" value="" class="select-amount-text" type="text" style="width:185px">
                      <span>직접 입력</span>
                    </div>
                  </li>
                </ul>
                <div class="mini_betting_confirm place-bet">배팅하기</div>
              </dd>
              <div class="bet-disable  hidden">지금 배팅을 할 수는 없습니다..
              </div>
            </dl>
            <div class="mini_table ladder-game-statistics">
              <h2>최근 회차별 통계</h2>
              <div class="history_list games-statistics">
                <div class="games-statistics-content" id="history_area">
                  <dl class="games-statistics-coll">
                    <dt class="games-statistics-title">짝</dt>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="red">209</h1><h2 class="left">3</h2></div>
                    </dd>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="red">208</h1><h2 class="left">3</h2></div>
                    </dd>
                  </dl>
                  <dl class="games-statistics-coll">
                    <dt class="games-statistics-title">홀</dt>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="blue">121</h1><h2 class="right">4</h2></div>
                    </dd>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="blue">120</h1><h2 class="right">4</h2></div>
                    </dd>
                  </dl>
                  <dl class="games-statistics-coll">
                    <dt class="games-statistics-title">홀</dt>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="blue">228</h1><h2 class="left">3</h2></div>
                    </dd>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="blue">227</h1><h2 class="left">3</h2></div>
                    </dd>
                  </dl>
                  <dl class="games-statistics-coll">
                    <dt class="games-statistics-title">짝</dt>
                    <dd class="games-statistics-item-wrap">
                      <div class="bet_circle"><h1 class="red">226</h1><h2 class="right">4</h2></div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="powerball_result_list mini_table powerball_result_list_inner" style="margin-bottom:30px">
              <h2 class="powerball">베팅내역</h2>
              <table id="power-ball-table">
                <colgroup>
                  <col width="41px">
                  <col width="71px">
                  <col width="108px">
                  <col width="111px">
                  <col width="134px">
                  <col width="113px">
                  <col width="71px">
                  <col width="95px">
                  <col width="106px">
                  <col width="96px">
                </colgroup>
                <thead class="powerball">
                  <tr>
                    <td scope="col"></td>
                    <td scope="col">번호</td>
                    <td scope="col">회차</td>
                    <td scope="col">배팅시간</td>
                    <td scope="col">게임 분류</td>
                    <td scope="col">베팅내역</td>
                    <td scope="col">배당</td>
                    <td scope="col">배팅 금액</td>
                    <td scope="col">승/패</td>
                    <td scope="col">승리</td>
                  </tr>
                </thead>
                <tbody class="betting_history">
                  <tr>
                    <td class="chk"></td>
                    <td class="num"></td>
                    <td class="date"></td>
                    <td class="time"></td>
                    <td class="sort"><b></b></td>
                    <td class="state"><strong></strong></td>
                    <td class="per"></td>
                    <td class="money01 td_right"></td>
                    <td class="money02 td_right"></td>
                    <td class="result "></td>
                  </tr>
                  <tr>
                    <td class="chk"></td>
                    <td class="num"></td>
                    <td class="date"></td>
                    <td class="time"></td>
                    <td class="sort"><b></b></td>
                    <td class="state"><strong></strong></td>
                    <td class="per"></td>
                    <td class="money01 td_right"></td>
                    <td class="money02 td_right"></td>
                    <td class="result "></td>
                  </tr>
                  <tr>
                    <td class="chk"></td>
                    <td class="num"></td>
                    <td class="date"></td>
                    <td class="time"></td>
                    <td class="sort"><b></b></td>
                    <td class="state"><strong></strong></td>
                    <td class="per"></td>
                    <td class="money01 td_right"></td>
                    <td class="money02 td_right"></td>
                    <td class="result "></td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <code class="btn-history-delete-selected">선택 삭제</code>
                <code class="btn-history-select-all">전체 선택</code>
                <code class="btn-view-allhistory">모든 배팅 내역</code>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Powerball'
}
</script>
<style scoped>
.body-wrap {width: 1140px;margin: 0 auto;background: #fff;padding-top: 180px;}
#mini_wrap {width: 1100px;margin: 0 auto;}
.minigame_menu {border: inherit;overflow: hidden;}
.minigame_menu>.mini_list {width: 860px;height: 54px;line-height: 54px;border: none;box-sizing: border-box;text-align: right;float: right;}
.minigame_menu>.mini_list>a {display: inline-block;float: left;width: 25%;margin-left: 0;box-sizing: border-box;font-size: 16px;color: #5e7d9b;}
.minigame_menu>.mini_list>a.active {color: #ca9423;text-decoration: inherit;}
.minigame_menu>.mini_list>a.active>div {background: #1c3d51;border-top-color: #ff7f00;}
.minigame_menu .mini_list a div>span:last-child {float: left;line-height: 20px;margin-top: 6px;}
.minigame_menu>.mini_list>a.disable input {background: linear-gradient(to bottom,#c1bab6 0%,#9a8984 100%);border: 1px solid #6f6462;pointer-events: none;}
.minigame_menu>.mini_list>a>.js-powerball-menu-container, .minigame_menu>.mini_list>a>.js-powerladder-menu-container, .minigame_menu>.mini_list>a>.js-speedkeno-menu-container, .minigame_menu>.mini_list>a>.js-kenoladder-menu-container, .minigame_menu>.mini_list>a>.js-fx1-menu-container {
    background: #222c36;border-top: 2px solid #0583bd;border-right: 1px solid #0583bd;height: 52px;}
.minigame_menu>.mini_list>a .css-powerball-img, .minigame_menu>.mini_list>a .css-powerladder-img, .minigame_menu>.mini_list>a .css-speedkeno-img, .minigame_menu>.mini_list>a .css-kenoladder-img, .minigame_menu>.mini_list>a .css-fx1-img, .minigame_menu>.mini_list>a .css-fx-ladder1-img {
    background-image: url("~@/assets/img/minigames-logo-icon01.png");background-position: center center;background-size: contain;background-repeat: no-repeat;float: left;height: 51px;width: 45px;margin-left: 5px;margin-right: 5px;}
.js-countdown-powerball, .js-countdown-powerladder, .js-countdown-speedkeno, .js-countdown-kenoladder, .js-countdown-fx1, .js-countdown-fxladder1 {
    background-image: url("~@/assets/img/minigames-clock-icon.png");background-repeat: no-repeat;background-position: 0 center;color: #fff600;float: left;font-size: 22px;font-weight: 600;padding-left: 20px;text-align: left;text-decoration: none;width: 75px;}
.js-powerball-menu-container input[type=button], .js-powerladder-menu-container input[type=button], .js-speedkeno-menu-container input[type=button], .js-kenoladder-menu-container input[type=button], .js-fx1-menu-container input[type=button] {
    background: #e94419;
    background: -moz-linear-gradient(top,#f16c35 0%,#e94419 100%);
    background: -webkit-linear-gradient(top,#f16c35 0%,#e94419 100%);
    background: linear-gradient(to bottom,#f16c35 0%,#e94419 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e94419',endColorstr='#f16c35',GradientType=0 );
    border: 1px solid #ab3112;
    border-radius: 5px;
    box-shadow: inset 1px 1px 0 rgb(255 255 255 / 30%);
    color: #fff;
    font-size: 14px;
    height: 40px;
    margin-top: -1px;
    margin-left: 3px;
    margin-right: 2px;
    text-align: center;
    text-shadow: 0 1px 0 #000;
    width: 56px;
    padding: 0;}
.mini_midwrapbox {width: inherit;background: inherit;border-left: 1px solid #e7e7e7;border-right: 1px solid #e7e7e7;margin: 0 auto;min-height: 1000px;box-sizing: border-box;}
#mini_boxwrap {background-color: #fff;overflow: hidden;}
#mini_box_right {width: 987px;margin: 0 auto;min-height: 1050px;}
.mini_gamemovie, .mini_timecount {text-align: center;}
.mini_gamemovie>div.power_ladder {height: 650px;}
.mini_gamemovie>div {position: relative;width: 830px;margin: 0 auto;overflow: hidden;transform: scale(.95);}
.mini_gamemovie>div.power_ladder>iframe {left: 0;top: 0;width: 830px;height: 650px;}
.mini_gamemovie>div>iframe {position: absolute;overflow: hidden;}
.mini_timecount {position: relative;background: inherit;border-top: 2px solid #858585;border-bottom: 1px solid #d2d2d2;position: relative;height: 37px;padding-top: 15px;font-size: 17px;}
.mini_timecount>font {display: inline-block;margin: 0 5px;color: #e77727;}
.mini_timecount>b {display: inline-block;margin-left: 10px;font-size: 19px;color: #959595;}
.mini_timecount>em {position: absolute;right: 15px;top: 15px;display: block;font-size: 12px;background: #555;color: #fff;border-radius: 5px;}
.mini_timecount>em, .timer_box>em {padding: 5px 10px;transition: .3s;cursor: pointer;}
.mini_bettingslip {border-top: 2px solid #858585;border-bottom: 1px solid #e7e7e7;margin-bottom: 20px;overflow: hidden;position: relative;}
.mini_bettingslip>dt {border-right: 1px solid #ccc;}
.mini_bettingslip>dd, .mini_bettingslip>dt {background: #fff;}
.mini_bettingslip>dd {float: left;width: 73%;padding-left: 2%;height: 200px;overflow: hidden;}
.mini_bettingslip>dt {float: left;width: calc(25% - 1px);height: 155px;padding-top: 45px;border-right: 1px solid #ccc;}
.mini_bettingslip>dt>div {margin-bottom: 15px;overflow: hidden;font-size: 16px;}
.mini_bettingslip>dt>div>em {color: #959595;font-size: 13px;display: block;float: left;width: 80px;margin: 0 10px 0 30px;}
.mini_bettingslip>dt>div>span.f_yellow {color: #595959;font-size: 13px;}
.mini_bettingslip>dt>div>span {display: block;float: left;width: 120px;color: #595959;font-size: 13px;}
div#selBet {padding-top: 5px;height: 40px;}
.mini_bettingslip>dt>div:nth-child(2) {margin-bottom: 15px;}
.mini_bettingslip>dd>h2 {color: #595959;margin: 22px 0 10px;font-size: 14px;display: block;font-size: 17px;}
.mini_bettingslip>dd>ul {float: left;margin-right: 3px;}
.mini_bettingslip>dd>ul>li:first-child {margin-bottom: 5px;}
.mini_bettingslip>dd>ul>li {overflow: hidden;margin-bottom: 4px;}
.mini_bettingslip>dd>ul>li>div {float: left;position: relative;margin-right: 5px;}
.mini_bettingslip>dd>ul>li>div>input {font-size: 13px;background: #fafafa;border: 1px solid #737373;width: 185px;height: 36px;text-align: right;padding: 0 20px 0 80px;border-radius: 4px;}
.mini_bettingslip>dd>ul>li>div>span {font-size: 14px;top: 9px;position: absolute;left: 15px;top: 12px;color: #333;}
.mini_bettingslip>dd>ul>li>code {width: 90px;height: 27px;line-height: 21px;background: linear-gradient(to top,#AAA,#888);border: 2px solid #999;border-radius: 5px;padding-top: 6px;display: block;float: left;text-align: center;color: #fff;font-size: 14px;margin-right: 3px;cursor: pointer;}
.mini_bettingslip>dd>ul>li>code:hover {background: linear-gradient(to top,#AAA,#888);opacity: .75;}
.mini_bettingslip>dd>ul>li>code.mini_betcalc_btn2 {color: #fff;background: #62b457;border: 2px solid #62b457;}
.mini_bettingslip>dd>ul>li>code.mini_betcalc_btn3 {color: #fff;background: #3a9c2d;border: 2px solid #3a9c2d;}
.mini_bettingslip>dd>ul>li>div>input.select-amount-text {color: #df6915;}
.mini_bettingslip>dd>div.mini_betting_confirm {background: #0583bd;border: 2px solid #0583bd;border-radius: 5px;width: 114px;float: left;height: 74px;padding-top: 44px;text-align: center;font-size: 20px;color: #fff;cursor: pointer;}
.mini_bettingslip>dd>div.mini_betting_confirm:hover {background: #0583bd;opacity: .75;}
.mini_bettingbtn {overflow: hidden;}
li.w3last {padding-top: 14px!important;}
.mini_bettingbtn>li>dl>dd:hover, .game-odd-active.pl-odd.active, .game-odd-active.pl-even.active, .game-odd-active.pl-left.active, .game-odd-active.pl-right.active, .game-odd-active.pl-line-3.active, .game-odd-active.pl-line-4.active, .game-odd-active.pl-under-5.active, .game-odd-active.pl-over-5.active {
    background: url("~@/assets/img/mini_betbtn1_hover.png");}
.games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd:hover, .games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd.active {
    background: url("~@/assets/img/mini_betbtn2_hover.png");}
.mini_bettingbtn>li.w3last dl.ladder_3rd dd.active, .mini_bettingbtn>li.w3last dl.ladder_3rd dd:hover {
    background: url("~@/assets/img/mini_betbtn2_hover.png");}
.mini_bettingbtn>li:hover {background: #fff;}
.mini_bettingbtn>li.w3last, .mini_bettingbtn>li.w3 {height: 190px;}
.mini_bettingbtn>li {padding-top: 15px;border-right: 1px solid #ccc;background: linear-gradient(to top,#FFF,#e6f2f8);width: calc(25% - 1px);font-size: 14px;}
.mini_bettingbtn>li, .powerball_bettingbtn>li {transition: .5s;text-align: center;float: left;overflow: hidden;}
.mini_bettingbtn>li>dl {margin-bottom: 7px;text-align: center;}
.w3 dd.game-odd-active.pl-odd {margin-top: -9px;}
.w3 dd.game-odd-active.pl-even {margin-top: -1px;margin-bottom: 5px;}
dd.game-odd-active.pl-even {margin-bottom: 4px;}
.game-odd-active.pl-odd, .game-odd-active.pl-even, .game-odd-active.pl-left, .game-odd-active.pl-right, .game-odd-active.pl-line-3, .game-odd-active.pl-line-4, .game-odd-active.pl-under-5, .game-odd-active.pl-over-5 {
    background-position: -3px -3302px;width: 97px;height: 137px;}
.mini_bettingbtn>li>dl>dd {padding-top: 45px;box-sizing: border-box;margin: 0 5px 5px 0;width: 97px;height: 137px;cursor: pointer;display: inline-block;background-image: url("~@/assets/img/sprite_games_min.png");background-repeat: no-repeat;overflow: hidden;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-odd>label {background-position: -2px -3px;width: 40px;}
.ladder-wrap ul.mini_bettingbtn dl>dd>label {display: block;height: 45px;margin: -7px auto 5px;width: 100%;}
.ladder-wrap ul.mini_bettingbtn dl>dd>label, .games-wrap .ladder-wrap dl.mini_bettingslip dt.cart_info #selBet span.active, .games-wrap .ladder-wrap .betting-history.mini_table table>tbody.betting_history>tr>td.state strong {
    background-image: url("~@/assets/img/ladder-wrap-sprite.png");background-repeat: no-repeat;background-position: 0 0;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-under-5>label, [lang*="/vn"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-under-5, [lang*="/id"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-under-5, [lang*="/th"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-under-5, [lang*="/jp"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-under-5 {
    background-position: -5px -300px;width: 75px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-even>label, [lang*="/vn"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-even, [lang*="/id"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-even, [lang*="/jp"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-even {
    background-position: -51px -3px;width: 40px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-over-5>label, [lang*="/vn"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-over-5, [lang*="/id"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-over-5, [lang*="/th"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-over-5, [lang*="/jp"] .board_wrap.betting_rulebox .betting_game>dl>dd>ul>li>label.pl-over-5 {
    background-position: -5px -352px;width: 75px;}
.games-wrap .ladder-wrap dl.mini_bettingslip dt.cart_info #selBet span.active.pl-even-under-5, .games-wrap .ladder-wrap .betting-history.mini_table table>tbody.betting_history>tr>td.state strong.s-even-under-5, .games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd.pl-even-under-5>label {
    background-position: -5px -601px;}
.games-wrap .ladder-wrap dl.mini_bettingslip dt.cart_info #selBet span.active.pl-odd-over-5, .games-wrap .ladder-wrap .betting-history.mini_table table>tbody.betting_history>tr>td.state strong.s-odd-over-5, .games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd.pl-odd-over-5>label {
    background-position: -65px -541px;}
.games-wrap .ladder-wrap dl.mini_bettingslip dt.cart_info #selBet span.active.pl-odd-under-5, .games-wrap .ladder-wrap .betting-history.mini_table table>tbody.betting_history>tr>td.state strong.s-odd-under-5, .games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd.pl-odd-under-5>label {
    background-position: -5px -541px;}
.games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd>label {
    margin: -4px auto 0;height: 35px;width: 35px;}
.games-wrap .ladder-wrap dl.mini_bettingslip dt.cart_info #selBet span.active.pl-even-over-5, .games-wrap .ladder-wrap .betting-history.mini_table table>tbody.betting_history>tr>td.state strong.s-even-over-5, .games-wrap .ladder-wrap ul.mini_bettingbtn li.w3last dl.ladder_3rd>dd.pl-even-over-5>label {
    background-position: -65px -601px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-line-even-left-3>label {background-position: -5px -481px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-line-odd-right-3>label {background-position: -5px -421px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-line-odd-left-4>label {background-position: -65px -421px;}
.ladder-wrap ul.mini_bettingbtn dl>dd.pl-line-even-right-4>label {background-position: -65px -481px;}
.ladder-wrap ul.mini_bettingbtn dl.type4>dd>label {margin: -4px auto 0;height: 35px;width: 35px;}
.mini_bettingbtn>li>dl.type4>dd>label {margin: -3px 0 0 0;}
.ladder-wrap ul.mini_bettingbtn dl>dd>label>.bet_circle {display: none;}
.mini_bettingbtn>li>dl.type4>dd {padding-top: 11px;}
.mini_bettingbtn>li>dl.type4>dd {height: 66px;padding-top: 10px;}
.game-odd-active.pl-line-even-left-3, .game-odd-active.pl-line-odd-right-3, .game-odd-active.pl-line-odd-left-4, .game-odd-active.pl-line-even-right-4 {
    background-position: -3px -3582px;width: 97px;height: 66px;}
.mini_bettingbtn>li>dl>dd.active>dfn {color: red;text-decoration: underline;}
.mini_table table>tbody.powerball>tr>td.oddeven label img, .w3 dd.game-odd-active.pl-even label img {height: 40px;}
.mini_bettingbtn>li>dl>dd>label {cursor: pointer;margin: -7px auto 5px;display: block;}
.ladder-wrap ul.mini_bettingbtn dl>dd>label>img {display: none;}
.mini_bettingbtn>li.w3>dl>dd>dfn, .mini_bettingbtn>li.w3last>dl>dd>dfn {margin-left: 0;}
.mini_bettingbtn>li>dl>dd>dfn {display: block;color: #373737;cursor: pointer;}
.mini_bettingbtn>li>dl>dd.active>dfn {color: red;text-decoration: underline;}
.mini_bettingbtn>li>em {background: #0583bd;border: 2px solid #0583bd;color: #fff;padding: 4px 10px;display: inline-block;line-height: 100%;border-radius: 20px;font-size: 12px;margin-right: 5px;}
.mini_bettingbtn>li>span {color: #595959;}
.mini_bettingbtn>li:last-child {width: 25%;border-right: 0;}
.mini_bettingbtn>li.w3last dl.ladder_3rd dd {background-position: -3px -3582px;width: 97px;height: 66px;padding-top: 11px;}
.mini_table>div.history_list {width: 100%;margin-top: 20px;border: 1px solid #353535;overflow-x: scroll;overflow-y: hidden;}
.games-statistics {overflow-x: scroll;width: 100%;margin: 30px 0 30px;border-bottom: 5px solid #353535;}
.mini_table>div.history_list>div {width: 100%;}
.games-statistics-content {width: 8000px;}
.games-statistics-content .games-statistics-coll:first-child {border-left: 1px solid #353535;border-left: inherit!important;}
.mini_table>div.history_list>div>dl {background: inherit;border-right: 1px solid #e7e7e7;float: left;position: relative;width: 45px;height: 495px;}
.games-statistics-content .games-statistics-coll {background-color: #111;border-right: 1px solid #353535;}
.games-statistics-coll {width: 42.2px;float: left;text-align: center;}
.mini_table>div.history_list>div>dl>dt {background: #f1f1f1;border-bottom: 1px solid #e7e7e7;color: #595959;font-size: 14px;}
.mini_table>div.history_list>div>dl>dt {height: 45px;text-align: center;font-size: 16px;line-height: 45px;}
.games-statistics-title {font-size: 13px;height: 40px;line-height: 40px;background: url("~@/assets/img/mini_table_thead_bg.png") repeat-x;}
.mini_table>div.history_list>div>dl>dd {height: 35px;padding: 5px 0;}
div.bet_circle {margin: 0 auto;}
.bet_circle>h1, div.bet_circle, var.bet_circle {width: 35px;height: 35px;}
div.bet_circle, var.bet_circle {position: relative;overflow: hidden;}
.bet_circle>h1.red {background: #e82e0f;}
.bet_circle>h1.blue {background: #1977e1;}
.bet_circle>h1 {text-align: center;font-size: 12px;color: #fff!important;line-height: 35px;border-radius: 34px;}
.bet_circle>h2.left {left: 0;}
.bet_circle>h2 {top: 0;color: #FFF;background: #000;}
.bet_circle>h2, .bet_circle>h3 {position: absolute;width: 12px;height: 11px;padding-top: 1px;font-size: 11px;line-height: 12px;border-radius: 12px;text-align: center;}
.bet_circle>h2.right, .bet_circle>h3.right, .mini_table table>tbody>tr>td>div>h2.right, .mini_table table>tbody>tr>td>div>h3.right {right: 0;}
.bet-disable {padding: 68px 0;}
.hidden {background: transparent;height: 0;line-height: 0;margin: 0;overflow: hidden;padding: 0;visibility: hidden;width: 0;display: none!important;}
.bet-disable {position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,.5);text-align: center;display: block;color: #fff;vertical-align: middle;font-size: 30px;padding: 95px;}
.hidden {display: none!important;}
.mini_table {padding: 0;margin-bottom: 30px;overflow: hidden;}
.mini_table>h2.powerball {margin-top: 10px;}
.mini_table>h2 {margin-bottom: 20px;padding: 10px 20px;font-size: 14px;color: #595959;width: 100%;background: linear-gradient(to top,#e6f2f8,#FFF);border-top: 2px solid #54a9d0;border-bottom: 1px solid #e7e7e7;display: block;float: left;}
.mini_table table {width: 947px;margin: 20px auto 0;border: none;}
.mini_table table>thead.powerball>tr {background-size: auto 100%;}
.mini_table table>thead>tr {border: 1px solid #e7e7e7;border-top: 2px solid #858585;background: inherit;}
.mini_table table>thead.powerball>tr>td {height: 45px;background-size: auto 100%;}
.mini_table table>thead>tr>td {font-size: 13px;color: #666;height: 66px;text-align: center;vertical-align: middle;}
.mini_table table>tbody>tr {border-bottom: 1px solid #e7e7e7;}
.mini_table table>tbody.powerball>tr>td {padding: 10px 0;}
.mini_table table>tbody>tr>td {background: inherit;border: none;color: #959595;text-align: center;font-size: 13px;}
.mini_table table>tbody.betting_history>tr>td {padding: 10px 0;font-size: 13px;height: 38px;vertical-align: middle;}
.mini_table table>tbody.powerball>tr>td.oddeven {color: #d20000;}
.mini_table table>tbody.powerball>tr>td.pb_odev {color: #0583bd;}
.mini_table>h3 {display: block;float: right;overflow: hidden;margin-top: 15px;}
.mini_table>h3>code {display: block;float: left;background: #004b8a;font-size: 13px;color: #fff;padding: 4px 15px;border-radius: 4px;margin-left: 5px;cursor: pointer;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
